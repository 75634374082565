.alert-dialog {
	min-width: 288px;
	width: fit-content;
	max-width: 366px;
	height: 183px;
	position: fixed;
	z-index: 33;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 16px 24px;
	max-height: fit-content;
	border-radius: 8px;
	mix-blend-mode: normal;
	background: var(--Darkblue-300);
	color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.dialog-header {
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	line-height: 28px;
	margin-bottom: 20px;
}

.dialog-icon {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	left: 51%;
	background-color: transparent;
	border-radius: 5000px;
	transform: translate(-50%, 0);
}

.dialog-body {
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	align-items: center;
	width: 78%;
	position: relative;
	left: 50%;
	transform: translate(-50%, 0);
	word-break: break-word;
	margin-bottom: 24px;
}

.dialog-single-button {
	display: flex;
	justify-content: center;
}

.dialog-cross-button {
	position: absolute;
	top: 12px;
	right: 28px;
}

.edit-modal {
	width: 362px;
	min-height: 400px;
	background: #051c2a;
	border-radius: 8px;
	position: fixed;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.edit-modal > .title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: #ffffff;
	margin: 16px 30px;
}
